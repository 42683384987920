import * as React from "react";
import { graphql } from "gatsby";
import { SVGMap } from "react-svg-map";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HeroSimpleCentered, Layout, SEO, LinkButton } from "../components";
import { mexicoMap } from "../config";

function visibleCities(cities) {
  return cities.filter(
    (c) =>
      c.enable_lead_form_residential ||
      c.enable_lead_form_business ||
      c.enable_lead_form_gdmth,
  );
}

const CoveragePage = ({ data }) => {
  const site = data.site;
  const [open, setOpen] = useState(true);
  const [stateSelected, setState] = useState(null);
  const states = data.allStrapiState.nodes;

  const metadata = site.siteMetadata;
  const metaPage = "cobertura";
  const metaTitle = "Cobertura";
  const metaDescription =
    "Conoce los estados y ciudades en las que opera Bright. Tenemos presencia en en toda la República Mexicana";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": metadata.siteUrl,
        url: metadata.siteUrl,
        name: metadata.title,
        description: metaDescription,
        inLanguage: "es-MX",
      },
      {
        "@type": "ImageObject",
        "@id": `${metadata.siteUrl}${metadata.defaultOgImage}#primaryimage`,
        inLanguage: "es-MX",
        url: `${metadata.siteUrl}${metadata.defaultOgImage}`,
      },
      {
        "@type": "WebPage",
        "@id": `${metadata.siteUrl}/${metaPage}/#webpage`,
        url: `${metadata.siteUrl}/${metaPage}`,
        name: `${metaTitle} · ${metadata.title}`,
        isPartOf: { "@id": metadata.siteUrl },
        primaryImageOfPage: {
          "@id": `${metadata.siteUrl}${metadata.defaultOgImage}`,
        },
        breadcrumb: {
          "@id": `${metadata.siteUrl}/${metaPage}/#breadcrumb`,
        },
        inLanguage: "es-MX",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${metadata.siteUrl}/${metaPage}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${metadata.siteUrl}/${metaPage}#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": metadata.siteUrl,
              url: metadata.siteUrl,
              name: "Inicio",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": `${metadata.siteUrl}/${metaPage}`,
              url: `${metadata.siteUrl}/${metaPage}`,
              name: `${metaTitle} · ${metadata.title}`,
            },
          },
        ],
      },
    ],
  };

  const sortCities = (cities) => {
    const citiesToShow = cities.filter(
      (c) =>
        c.enable_lead_form_residential ||
        c.enable_lead_form_business ||
        c.enable_lead_form_gdmth,
    );
    const cityNames = citiesToShow.map((city) => city.name).sort();

    if (cityNames.length === 0) {
      return null;
    }

    // Spanish grammatical rule
    const lastCity = cityNames[cityNames.length - 1].toLowerCase();
    let and = "y";
    // Spanish grammatical rule
    if (
      lastCity.startsWith("i") ||
      lastCity.startsWith("hi", 2) ||
      lastCity.startsWith("y")
    ) {
      and = "e";
    }

    return cityNames.join(", ").replace(/, ((?:.(?!, ))+)$/, ` ${and} $1`);
  };

  const getLocationClassName = (location) => {
    const found = states.find((s) => s.iso_code == location.id);

    return found
      ? "outline-none	fill-current text-yellow-500 cursor-pointer hover:fill-current hover:text-blue-500"
      : "outline-none	fill-current text-gray-300";
  };

  const handleLocationClick = (s) => {
    setState(s.target.id);
    setOpen(true);
  };

  const StateModal = () => {
    const state = states.find((s) => s.iso_code == stateSelected);

    return (
      <>
        {state && stateSelected && (
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 overflow-hidden"
              onClose={setOpen}
            >
              <div className="absolute inset-0 overflow-hidden">
                <Dialog.Overlay className="absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />

                <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <div className="w-screen max-w-md">
                      <form className="h-full flex flex-col bg-white shadow-xl">
                        <div className="flex-1 h-0 overflow-y-scroll">
                          <div className="py-6 px-4 bg-gray-50 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                {state.name}
                              </Dialog.Title>
                              <div className="ml-3 h-7 flex items-center">
                                <button
                                  type="button"
                                  className="text-gray-400 hover:text-gray-500"
                                  onClick={() => setOpen(false)}
                                >
                                  <XMarkIcon className="h-6 w-6" />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1">
                              <p className="text-sm text-gray-500">
                                Ciudades en las que tenemos presencia
                              </p>
                            </div>
                          </div>
                          <div className="flex-1 flex flex-col justify-between">
                            <div className="px-4 pb-5 sm:px-0 divide-y divide-gray-200">
                              {visibleCities(state.cities).map(
                                (item, index) => (
                                  <dl key={index} className="px-4 sm:px-6 py-6">
                                    <h3 className="text-base leading-6 font-medium text-gray-900">
                                      {item.name}
                                    </h3>
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-2">
                                      {item.enable_lead_form_residential && (
                                        <div className="sm:col-span-1">
                                          <dd className="mt-1 text-sm text-gray-900">
                                            $
                                            {item.minimum_bimonthly_residential_rate?.toLocaleString()}{" "}
                                            (bimestral)
                                          </dd>
                                          <dt className="text-sm font-medium text-gray-500">
                                            Consumo min. Hogar
                                          </dt>
                                        </div>
                                      )}
                                      {item.enable_lead_form_business && (
                                        <div className="sm:col-span-1">
                                          <dd className="mt-1 text-sm text-gray-900">
                                            $
                                            {item.minimum_bimonthly_business_rate?.toLocaleString()}{" "}
                                            (bimestral)
                                          </dd>
                                          <dt className="text-sm font-medium text-gray-500">
                                            Consumo min. Negocio
                                          </dt>
                                        </div>
                                      )}
                                      {item.enable_lead_form_gdmth && (
                                        <div className="sm:col-span-1">
                                          <dd className="mt-1 text-sm text-gray-900">
                                            $30,000 (al mes)
                                          </dd>
                                          <dt className="text-sm font-medium text-gray-500">
                                            Consumo min. Industria
                                          </dt>
                                        </div>
                                      )}
                                    </dl>
                                  </dl>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0 px-4 py-4">
                          <LinkButton to="/cotizar">
                            Solicitar propuesta
                          </LinkButton>
                        </div>
                      </form>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </>
    );
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaMarkup={schemaMarkup}
      />
      <HeroSimpleCentered
        title="Área de cobertura"
        description="Tenemos presencia en toda la República Mexicana"
      />
      <div className="bg-white">
        <div className="flex justify-center mx-auto max-w-2xl md:max-w-3xl lg:max-w-4xl">
          <SVGMap
            map={mexicoMap}
            locationClassName={getLocationClassName}
            onLocationClick={handleLocationClick}
          />
        </div>
      </div>
      <div className="bg-white">
        <div className="relative mx-auto divide-y-2 divide-gray-200 px-8 pb-16 lg:pb-24 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl">
          <div className="mt-6 grid gap-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {states.map((item, index) => (
              <div key={index}>
                <div className="mt-2 block">
                  <h3 className="text-lg lg:text-xl font-semibold text-gray-900">
                    {item.name}
                  </h3>
                  <p className="mt-3 text-base text-gray-500">
                    {sortCities(item.cities)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
            ¿No ves tu ciudad aún? ¡No te preocupes!
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            ¡Estamos trabajando arduamente por extendernos para que cada hogar
            mexicano pueda gozar de la energía solar!
          </p>
        </div>
      </div>
      <StateModal />
    </Layout>
  );
};

export default CoveragePage;

export const query = graphql`
  query {
    allStrapiState(sort: { order: ASC, fields: slug }) {
      nodes {
        name
        slug
        iso_code
        cities {
          slug
          name
          minimum_bimonthly_business_rate
          minimum_bimonthly_residential_rate
          enable_lead_form_residential
          enable_lead_form_business
          enable_lead_form_gdmth
        }
      }
    }
    site {
      ...SiteInformationFragment
    }
  }
`;
